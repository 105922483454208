var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-options"},[_c('c-link',{on:{"click":function($event){_vm.isTeamDrawerOpen = !_vm.isTeamDrawerOpen}}},[_c('svg',[_c('use',{attrs:{"href":require("@/assets/icons/team-fill.svg") + "#team"}})])]),_c('c-box',{staticClass:"notification",attrs:{"mr":"5","ml":"5"}},[_c('Notification')],1),_c('c-link',{attrs:{"mr":"4"},on:{"click":_vm.openSavedPost}},[_c('c-icon',{staticStyle:{"height":"23px"},attrs:{"color":"gray.500","name":"bookmark"}})],1),_c('c-popover',{attrs:{"placement":"bottom"}},[_c('c-popover-trigger',[_c('c-avatar',{attrs:{"size":"sm","name":`${_vm.user.firstname} ${_vm.user.lastname}`,"src":_vm.user.profilePhoto,"borderWidth":"0px","cursor":"pointer"}},[_c('c-avatar-badge',{attrs:{"size":"1.0em","bg":"red.400"}})],1)],1),_c('c-popover-content',{attrs:{"w":"250px","zIndex":"modal"}},[_c('c-popover-body',{attrs:{"p":"0"}},[_c('c-list',[[_c('c-list-item',{attrs:{"display":"flex","borderBottomWidth":"1px"}},[_c('c-pseudo-box',{attrs:{"as":"a","py":"2","px":"3","w":"100%","_hover":{
                  textDecoration: 'none',
                  cursor: 'pointer',
                }},on:{"click":function($event){return _vm.$router.push({ name: 'Slack' })}}},[(!_vm.user.slackData)?_c('img',{attrs:{"alt":"Add to Slack","height":"40","width":"139","src":"https://platform.slack-edge.com/img/add_to_slack.png","srcSet":"https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"}}):_c('c-text',[_vm._v("Integrated to Slack")])],1)],1)],(_vm.isCompanyOwner)?_c('c-list-item',{attrs:{"display":"flex","borderBottomWidth":"1px"}},[_c('c-pseudo-box',{attrs:{"as":"a","py":"2","px":"3","w":"100%","_hover":{
                textDecoration: 'none',
                cursor: 'pointer',
              }},on:{"click":function($event){return _vm.$router.push('/app/overview')}}},[_vm._v("Company Settings")])],1):_vm._e(),_c('c-list-item',{attrs:{"display":"flex","borderBottomWidth":"1px"}},[_c('c-pseudo-box',{attrs:{"as":"a","py":"2","px":"3","w":"100%","_hover":{
                textDecoration: 'none',
                cursor: 'pointer',
              }},on:{"click":_vm.goToProfile}},[_vm._v("Profile")])],1),_c('c-list-item',{attrs:{"display":"flex"}},[_c('c-pseudo-box',{attrs:{"as":"a","py":"2","px":"3","w":"100%","color":"vc-orange.400","_hover":{
                textDecoration: 'none',
                cursor: 'pointer',
              }},on:{"click":_vm.onLogoutClick}},[_vm._v("Logout (v2.0.9)")])],1)],2)],1)],1)],1),_c('TeamDrawer',{attrs:{"isOpen":_vm.isTeamDrawerOpen},on:{"close":_vm.closeTeamDrawer}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }